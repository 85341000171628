<template>
  <div class="content"></div>
</template>

<script>
export default {
  name: 'Dashboard',
  components: {},
  data() {
    return {};
  },
  created() {
    this.$router.push({ name: 'Positions' });
  },
};
</script>

<style lang="scss" scoped>
</style>
